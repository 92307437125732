import { FC, ReactElement, useEffect } from 'react';

import { endOfDay } from 'date-fns';
import { useSelector } from 'react-redux';

import {
    Absence,
    LeaveOfAbsence,
    UnavailableToWorkTimeSlot,
    User,
} from '../../../models';
import Calendar from '../../../pages/Management/components/Calendar/Calendar';
import { getUserShifts } from '../../../redux/management/managementActions';
import { Reducers } from '../../../redux/reducers';
import { setTimePeriodAndSelectedDays } from '../../redux/@interface/calendar/calendarActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { getUserTracks } from '../../redux/user/userActions';
import { TimeModeType } from '../../types';

interface ConnectedUserCalendarProps {
    isLoading: boolean;
    legacyUser: User;
    userId: string;
    onItemClick: (object: Absence | LeaveOfAbsence | UnavailableToWorkTimeSlot) => void;
    className?: string;
}

const ConnectedUserCalendar: FC<ConnectedUserCalendarProps> = ({
    isLoading,
    legacyUser,
    userId,
    onItemClick,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const { selectedDays, selectedMobileDay, timeMode } = useTypedSelector(state => state.calendarReducer);
    const { payrollPeriods } = useTypedSelector(state => state.pacoPayrollPeriodsReducer);

    const legacyShifts = useSelector((state: Reducers) => state.managementReducer.userShifts);
    const tracks = useSelector((state: Reducers) => state.pacoUserReducer.userTracks);

    const absences = legacyUser?.absences || [];
    const leaveOfAbsences = legacyUser?.leaveOfAbsences || [];
    const unavailableToWorkTimeSlots = legacyUser?.unavailableToWorkTimeSlots || [];

    useEffect((): void => {
        if (payrollPeriods.length && timeMode !== TimeModeType.period) {
            dispatch(setTimePeriodAndSelectedDays(TimeModeType.period, new Date(), payrollPeriods));
        }
    }, [payrollPeriods]);

    useEffect((): void => {
        if (timeMode !== TimeModeType.period && timeMode !== TimeModeType.month) {
            return;
        }

        const startDate = selectedDays[0];
        const endDate = endOfDay(selectedDays[selectedDays.length - 1]);

        dispatch(getUserShifts(userId, startDate, endDate));
        dispatch(getUserTracks({ start: startDate, end: endDate }, userId));
    }, [selectedDays]);

    return (
        <Calendar
            isLoading={isLoading}
            absences={absences}
            days={selectedDays}
            leaveOfAbsences={leaveOfAbsences}
            mobileDay={selectedMobileDay}
            shifts={legacyShifts}
            timeMode={timeMode}
            tracks={tracks}
            unavailableToWorkTimeSlots={unavailableToWorkTimeSlots}
            userId={userId}
            onItemClick={onItemClick}
            className={className}
        />
    );
};

export default ConnectedUserCalendar;
