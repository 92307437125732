import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';

import { SidebarPageWrapper } from '../../@paco/components';
import { LeaveOfAbsencesCalendarLegend, Sidebar } from '../../@paco/compositions';
import { ConnectedHelpTooltip } from '../../@paco/connectors';
import trans from '../../@paco/helpers/trans';
import ButtonAuthenticate from '../../components/ButtonAuthenticate/ButtonAuthenticate';
import NewFilters from '../../components/Filters/Filters';
import LogsModal from '../../components/Logs/LogsModal';
import SearchBar from '../../components/SearchBar/SearchBar';
import {
    LEAVE_OF_ABSENCE_REQUESTS_CALENDAR,
    LEAVE_OF_ABSENCES_REQUEST_APPROVED,
    LEAVE_OF_ABSENCES_REQUEST_CLOSED,
    LEAVE_OF_ABSENCES_REQUEST_OPEN,
} from '../../constants';
import { checkPermission, removeLocationQueries, scrollToElementAndCenter } from '../../helpers';
import { translate } from '../../helpers/translations/translator';
import { addLeaveOfAbsenceRequest, editLeaveOfAbsence, getLeaveOfAbsenceRequests } from '../../redux/absences-ts/absencesActions';
import { setEmployeeSearch, setFilter } from '../../redux/filter/filterActions';
import { clearResourceLogs, getResourceLogs } from '../../redux/logs/logsActions';
import { setStartAndEndDate } from '../../redux/weekNavigator/weekNavigatorActions';
import CalendarToolbar from '../Schedule/components/CalendarToolbar/CalendarToolbar';
import Days from './components/Days/Days';
import AddLeaveOfAbsenceForm from './forms/AddLeaveOfAbsenceForm';
import DeclineLeaveOfAbsenceForm from './forms/DeclineLeaveOfAbsenceForm';
import ResolveLeaveOfAbsenceForm from './forms/ResolveLeaveOfAbsenceForm/ResolveLeaveOfAbsenceForm';

import './LeaveOfAbsencesCalendar.scss';


const getFocusAbsence = () => window.location.search.split('?focusAbsence=')[1] || '';

class LeaveOfAbsenceCalendar extends Component {
    state = {
        activeAbsence: null,
        resolveAbsenceForm: false,
        declineForm: false,
        addLeaveOfAbsenceForm: false,
        focusAbsence: null,
        payrollPeriodsWithHours: null,
        weeksWithHours: null,
        leaveType: null,
    };

    componentDidMount() {
        this.getLeaveOfAbsences();
        const absenceId = getFocusAbsence();
        window.addEventListener('mousedown', this.onWindowClick);

        this.setState({
            focusAbsence: absenceId,
        });
    }

    componentDidUpdate(prevProps) {
        const { focusAbsence } = this.state;
        const activeDepartmentOptions = this.props.departmentOptions.filter(option => option.isChecked);
        const prevActiveDepartmentOptions = prevProps.departmentOptions.filter(option => option.isChecked);

        if (focusAbsence) {
            const element = document.getElementById(`absence-${focusAbsence}`);

            if (element) scrollToElementAndCenter(element);
        }

        if (activeDepartmentOptions.length !== prevActiveDepartmentOptions.length) {
            this.getLeaveOfAbsences();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('mousedown', this.onWindowClick);
    }

    onWindowClick = () => {
        // Remove queries after first interaction from user, so we don't get any
        // repeating "focus animations".
        removeLocationQueries();
        this.setState({
            focusAbsence: '',
        });
        window.removeEventListener('mousedown', this.onWindowClick);
    };

    dispatchDeclineLeaveOfAbsence = (declineComment) => {
        const {
            activeAbsence,
            payrollPeriodsWithHours,
            weeksWithHours,
            leaveType,
        } = this.state;

        this.setState({
            resolveAbsenceForm: false,
            declineForm: false,
            activeAbsence: null,
            payrollPeriodsWithHours: null,
            weeksWithHours: null,
            leaveType: null,
        });
        this.props.dispatch(editLeaveOfAbsence({
            editType: 'deny',
            declineComment,
            leaveType,
            payrollPeriodsWithHours,
            weeksWithHours,
            leaveOfAbsence: activeAbsence,
        }));
    };

    dispatchEditLeaveOfAbsence = (
        editType,
        data,
        periodsWithHours,
        weeksWithHours,
        leaveType,
    ) => {
        const { activeAbsence } = this.state;

        this.setState({
            resolveAbsenceForm: false,
            activeAbsence: null,
        });
        this.props.dispatch(editLeaveOfAbsence({
            editType,
            data,
            leaveType,
            payrollPeriodsWithHours: periodsWithHours,
            weeksWithHours,
            leaveOfAbsence: activeAbsence,
        }));
    };

    dispatchAddLeaveOfAbsence = (
        userId,
        data,
        periodsWithHours,
        weeksWithHours,
        comment,
        leaveType,
    ) => {
        this.props.dispatch(addLeaveOfAbsenceRequest({
            userId,
            data,
            periodsWithHours,
            weeksWithHours,
            comment,
            leaveType,
        }));
        this.setState({
            addLeaveOfAbsenceForm: false,
        });
    };

    dispatchOpenLogsModal = (id) => {
        this.props.dispatch(getResourceLogs(id, 'leave-of-absences'));
    };

    dispatchCloseLogsModal = () => {
        this.props.dispatch(clearResourceLogs());
    };

    dispatchSetFilters = (filter) => {
        this.props.dispatch(setFilter(filter, this.getLeaveOfAbsences));
    };

    getLeaveOfAbsences = () => {
        const status = this.getStatus();
        this.props.dispatch(getLeaveOfAbsenceRequests(LEAVE_OF_ABSENCE_REQUESTS_CALENDAR, status));
    };

    getStatus = () => {
        const { showDenied } = this.props.filter.absences;
        const status = [LEAVE_OF_ABSENCES_REQUEST_OPEN, LEAVE_OF_ABSENCES_REQUEST_APPROVED];

        if (showDenied) status.push(LEAVE_OF_ABSENCES_REQUEST_CLOSED);

        return status;
    };

    addResolveAbsenceForm = (absence) => {
        this.setState({
            resolveAbsenceForm: true,
            activeAbsence: absence,
        });
    };

    onSubmitLeaveOfAbsenceForm = (
        editType,
        data,
        payrollPeriodsWithHours,
        weeksWithHours,
        leaveType,
    ) => {
        if (editType === 'deny') {
            this.addDeclineLeaveOfAbsenceForm(
                payrollPeriodsWithHours,
                weeksWithHours,
                leaveType,
            );
        } else {
            this.dispatchEditLeaveOfAbsence(
                editType,
                data,
                payrollPeriodsWithHours,
                weeksWithHours,
                leaveType,
            );
        }
    };

    addDeclineLeaveOfAbsenceForm = (payrollPeriodsWithHours, weeksWithHours, leaveType) => {
        this.setState({
            payrollPeriodsWithHours,
            weeksWithHours,
            leaveType,
            resolveAbsenceForm: false,
            declineForm: true,
        });
    };

    addLeaveOfAbsence = () => {
        this.setState({
            addLeaveOfAbsenceForm: true,
        });
    };

    setStartAndEndDate = (selectedDate, startDate, endDate) => {
        this.props.dispatch(setStartAndEndDate(selectedDate, startDate, endDate));
        this.getLeaveOfAbsences();
    };

    onSearchBarChange = (value) => {
        this.props.dispatch(setEmployeeSearch(value));
        this.getLeaveOfAbsences();
    };

    renderDays(selectedDays, mobile = false) {
        const { focusAbsence } = this.state;
        const {
            loading, calendarLeaveOfAbsencesRequests, timeMode, filter, lastChangedLeaveOfAbsenceId,
            syncLoketRequest,
        } = this.props;
        const { calendarViewProps } = filter.absences;
        const focusId = focusAbsence || lastChangedLeaveOfAbsenceId;

        return (
            <Days
                absences={calendarLeaveOfAbsencesRequests}
                focusAbsenceId={!loading ? focusId : ''}
                showHeader={!mobile}
                mobile={mobile}
                selectedDays={selectedDays}
                loading={loading || syncLoketRequest}
                onAbsenceClick={this.addResolveAbsenceForm}
                mode={timeMode}
                filter={calendarViewProps}
            />
        );
    }

    renderLeaveOfAbsences() {
        const {
            activeAbsence,
            resolveAbsenceForm,
            declineForm,
            addLeaveOfAbsenceForm,
        } = this.state;
        const {
            selectedDays,
            selectedDate,
            employees,
            permissions,
            pacoPayrollPeriods,
            payrollPeriods,
            currentUser,
            logs = null,
            logsLoading,
        } = this.props;

        return (
            <>
                <div className="calendar-days-desktop">
                    {this.renderDays(selectedDays)}
                </div>
                <div className="calendar-days-mobile leave-of-absences-calendar-days-mobile">
                    {this.renderDays([selectedDate], true)}
                </div>
                <div className="calendar-days-mobile" />
                <Modal size="lg" isOpen={resolveAbsenceForm} className="form-resolve-leave-of-absence">
                    <ResolveLeaveOfAbsenceForm
                        absence={activeAbsence}
                        currentUserRole={currentUser.role}
                        permissions={permissions}
                        pacoPayrollPeriods={pacoPayrollPeriods}
                        payrollPeriods={payrollPeriods}
                        onSubmit={this.onSubmitLeaveOfAbsenceForm}
                        onCancel={() => this.setState({ resolveAbsenceForm: false })}
                        onShowLogsClick={() => this.dispatchOpenLogsModal(activeAbsence.id)}
                    />
                </Modal>
                <Modal size="lg" isOpen={declineForm} className="form-decline-leave-of-absence">
                    <DeclineLeaveOfAbsenceForm
                        onSubmit={this.dispatchDeclineLeaveOfAbsence}
                        onCancel={() => this.setState({
                            declineForm: false,
                            activeAbsence: null,
                        })}
                    />
                </Modal>
                <Modal size="lg" isOpen={addLeaveOfAbsenceForm} className="form-add-leave-of-absence">
                    <AddLeaveOfAbsenceForm
                        employees={employees}
                        pacoPayrollPeriods={pacoPayrollPeriods}
                        payrollPeriods={payrollPeriods}
                        permissions={permissions}
                        onSubmit={this.dispatchAddLeaveOfAbsence}
                        onCancel={() => this.setState({ addLeaveOfAbsenceForm: false })}
                    />
                </Modal>
                <LogsModal
                    loading={logsLoading}
                    isOpen={logsLoading || !!logs}
                    title={translate('logs.leaveOfAbsenceLog')}
                    logs={logs}
                    onClose={this.dispatchCloseLogsModal}
                />
            </>
        );
    }

    render() {
        const {
            payrollPeriods,
            departments,
            filter,
            timeMode,
            permissions,
            roles,
            employmentTypes,
        } = this.props;
        const { employeeSearch } = filter;
        const canAddLeaveOfAbsence = checkPermission(permissions, 'add-new-leave-of-absences', 'leave-of-absences-calendar')
            || checkPermission(permissions, 'add-new-employee-leave-of-absences', 'leave-of-absences-calendar')
            || checkPermission(permissions, 'add-new-junior-manager-leave-of-absences', 'leave-of-absences-calendar');

        return (
            <>
                <SidebarPageWrapper>
                    <Sidebar>
                        <NewFilters
                            departments={departments}
                            filter={filter}
                            roles={roles}
                            employmentTypes={employmentTypes}
                            button={canAddLeaveOfAbsence ? (
                                <ButtonAuthenticate onClick={this.addLeaveOfAbsence} text={translate('pages.absences.addLeaveOfAbsence')} />
                            ) : null}
                            filterBlocks={[
                                'show-denied-leave-of-absence-requests',
                                'calendar-view-props',
                                'user-types',
                                'contract-types',
                                'main-department',
                            ]}
                            onChange={this.dispatchSetFilters}
                        />
                        <LeaveOfAbsencesCalendarLegend
                            className="leave-of-absences-calendar__legend"
                        />
                    </Sidebar>
                    <main className="leave-of-absences-calendar">
                        <div className="leave-of-absences-calendar__list-top">
                            <CalendarToolbar
                                payrollPeriods={payrollPeriods}
                                timeMode={timeMode}
                                setStartAndEndDate={this.setStartAndEndDate}
                                afterDateChange={this.getLeaveOfAbsences}
                            />
                            <div className="leave-of-absences-calendar__list-top-right">
                                <SearchBar placeholder={translate('common.searchEmployee')} startVal={employeeSearch} onChange={this.onSearchBarChange} />
                                <ConnectedHelpTooltip
                                    index={0}
                                    route="leave-of-absences-calendar"
                                    subTitle={trans('help.absences.viewOptions.title')}
                                    text={trans('help.absences.viewOptions.text')}
                                    title={trans('help.absences.title')}
                                    showMobileInfoWarning
                                    className="leave-of-absences-calendar__view-options-help-tooltip"
                                />
                            </div>
                        </div>
                        {this.renderLeaveOfAbsences()}
                        <ConnectedHelpTooltip
                            index={1}
                            route="leave-of-absences-calendar"
                            subTitle={trans('help.absences.addLeaveOfAbsence.title')}
                            text={trans('help.absences.addLeaveOfAbsence.text')}
                            title={trans('help.absences.title')}
                            className="leave-of-absences-calendar__add-leave-of-absence-help-tooltip"
                        />
                        <ConnectedHelpTooltip
                            index={2}
                            route="leave-of-absences-calendar"
                            subTitle={trans('help.absences.leaveOfAbsencesFilters.title')}
                            text={trans('help.absences.leaveOfAbsencesFilters.text')}
                            title={trans('help.absences.title')}
                            showMobileInfoWarning
                            className="leave-of-absences-calendar__filters-help-tooltip"
                        />
                        <ConnectedHelpTooltip
                            index={3}
                            route="leave-of-absences-calendar"
                            subTitle={trans('help.absences.leaveOfAbsenceItem.title')}
                            text={trans('help.absences.leaveOfAbsenceItem.text')}
                            title={trans('help.absences.title')}
                            className="leave-of-absences-calendar__leave-of-absence-item-help-tooltip"
                        />
                    </main>
                </SidebarPageWrapper>
                <ButtonAuthenticate
                    onClick={this.addLeaveOfAbsence}
                    mobile
                />
            </>
        );
    }
}

LeaveOfAbsenceCalendar.propTypes = {
    loading: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    departments: PropTypes.array.isRequired,
    departmentOptions: PropTypes.array.isRequired,
    employmentTypes: PropTypes.array.isRequired,
    pacoPayrollPeriods: PropTypes.array.isRequired,
    payrollPeriods: PropTypes.array.isRequired,
    roles: PropTypes.array.isRequired,
    permissions: PropTypes.array.isRequired,
    currentUser: PropTypes.object.isRequired,
    filter: PropTypes.object.isRequired,
    employees: PropTypes.array.isRequired,
    timeMode: PropTypes.string.isRequired,
    calendarLeaveOfAbsencesRequests: PropTypes.array.isRequired,
    selectedDays: PropTypes.array.isRequired,
    selectedDate: PropTypes.object.isRequired,
    lastChangedLeaveOfAbsenceId: PropTypes.string.isRequired,
    logs: PropTypes.array,
    logsLoading: PropTypes.bool.isRequired,
};

export default connect()(LeaveOfAbsenceCalendar);
