import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'reactstrap';

import {
    addDays,
    addSeconds,
    getEndOfWorkDay,
    getStartOfWorkDay,
} from '../../../../@paco/helpers/date';
import { setUserSearch } from '../../../../@paco/redux/@interface/globalFilters/globalFiltersReducer';
import { getPacoPayrollPeriods } from '../../../../@paco/redux/payrollPeriods/payrollPeriodsActions';
import { fetchSpecialDays } from '../../../../@paco/redux/specialDays/specialDaysActions';
import { useTypedSelector } from '../../../../@paco/redux/store';
import { ScheduleRoutes } from '../../../../@paco/routes/helpers';
import { PrivateRouteGroupRoutes } from '../../../../@paco/routes/routes';
import { TimeModeType } from '../../../../@paco/types';
import { PzLeaveType } from '../../../../@paco/types/leaveType';
import NewFilters from '../../../../components/Filters/Filters';
import LogsModal from '../../../../components/Logs/LogsModal';
import SidebarPage from '../../../../components/SidebarPage/SidebarPage';
import { LIST_VIEW } from '../../../../constants';
import { checkPermission } from '../../../../helpers';
import { translate } from '../../../../helpers/translations/translator';
import {
    Absence,
    LeaveOfAbsence,
    LeaveOfAbsenceEditType,
    LeaveOfAbsenceFormData,
    LeaveType,
    PayrollPeriodViewModel,
    PayrollPeriodWithHours,
    UnavailableToWorkTimeSlot,
    UserWithAvailability,
    WeekWithHours,
} from '../../../../models';
import { deleteAbsence, editAbsence, editLeaveOfAbsence } from '../../../../redux/absences-ts/absencesActions';
import { getPayrollPeriods, switchMode } from '../../../../redux/app/appActions';
import { setFilter } from '../../../../redux/filter/filterActions';
import { AppFilter } from '../../../../redux/filter-ts/filterModels';
import { clearResourceLogs, getResourceLogs } from '../../../../redux/logs/logsActions';
import { setUserPlanningPreference } from '../../../../redux/management/managementActions';
import { setPaginationNumber } from '../../../../redux/pagination/paginationActions';
import { Reducers } from '../../../../redux/reducers';
import { getUsersWithAvailability, planAvailabilityUserToShift } from '../../../../redux/shifts-ts/shiftsActions';
import { TypedDispatch } from '../../../../redux/store';
import { setTimeMode } from '../../../../redux/weekNavigator/weekNavigatorActions';
import DeclineLeaveOfAbsenceForm from '../../../Absences/forms/DeclineLeaveOfAbsenceForm';
import EditAbsenceForm from '../../../Absences/forms/EditAbsenceForm/EditAbsenceForm';
import ResolveLeaveOfAbsenceForm from '../../../Absences/forms/ResolveLeaveOfAbsenceForm/ResolveLeaveOfAbsenceForm';
import AddOrEditAvailabilityTime from '../../../Management/forms/AddOrEditAvailabilityTime/AddOrEditAvailabilityTime';
import { AvailabilityResourceTypes } from '../../components/AvailabilityDays/subcomponents/Availability/Availability';
import ListView from '../../containers/ListView/ListView';
import AssignShiftToUserForm from '../../forms/AssignShiftToUserForm/AssignShiftToUserForm';

const ConnectedListView: FC = (): ReactElement => {
    const dispatch = useDispatch<TypedDispatch>();
    const navigate = useNavigate();

    const { selectedDays, timeMode } = useTypedSelector(state => state.calendarReducer);
    const { payrollPeriods } = useTypedSelector(state => state.pacoPayrollPeriodsReducer);
    const legacyPayrollPeriods = useSelector<Reducers, PayrollPeriodViewModel[]>(state => state.appReducer.payrollPeriods);
    const { specialDays } = useSelector((state: Reducers) => state.specialDaysReducer);
    const { permissions, role: currentUserRole } = useTypedSelector(state => state.authenticatedUserReducer);
    const { departmentOptions, userSearch } = useTypedSelector(state => state.globalFiltersReducer);
    const users = useSelector((state: Reducers) => state.shiftsReducer.usersWithAvailability);
    const shiftsLoading = useSelector((state: Reducers) => state.shiftsReducer.loading);
    const absencesLoading = useSelector((state: Reducers) => state.absencesReducer.loading);
    const managementLoading = useSelector((state: Reducers) => state.managementReducer.loading);
    const { logs, loading: logsLoading } = useSelector((state: Reducers) => state.logsReducer);
    const { isLoading: isShiftPlanningLoading, isEditSuccessful } = useSelector((state: Reducers) => state.shiftPlanningReducer);
    const filter = useSelector((state: Reducers) => state.filterReducer.filter);
    const pagination = useSelector((state: Reducers) => state.paginationReducer);

    const loading = shiftsLoading || absencesLoading || managementLoading || isShiftPlanningLoading;
    const canAddPlannings = checkPermission(permissions, 'add-new-shift-plannings');
    const canViewShiftConcepts = checkPermission(permissions, 'view-all-shift-concepts');
    const canViewAbsences = checkPermission(permissions, 'view-all-absences');
    const canViewLeaveOfAbsences = checkPermission(permissions, 'view-all-leave-of-absences');

    const [resolveLeaveOfAbsenceForm, setResolveLeaveOfAbsenceForm] = useState(false);
    const [activeLeaveOfAbsence, setActiveLeaveOfAbsence] = useState<LeaveOfAbsence | null>(null);
    const [declineLeaveOfAbsenceForm, setDeclineLeaveOfAbsenceForm] = useState(false);
    const [editLOAData, setEditLOAData] = useState<LeaveOfAbsenceFormData | null>(null);
    const [editLOAPayrollPeriodHours, setEditLOAPayrollPeriodHours] = useState<PayrollPeriodWithHours[]>([]);
    const [editLOAWeekdayHours, setEditLOAWeekdayHours] = useState<WeekWithHours[]>([]);
    const [editAbsenceForm, setEditAbsenceForm] = useState(false);
    const [activeAbsence, setActiveAbsence] = useState<Absence | null>(null);
    const [editPlanningPreferenceForm, setEditPlanningPreferenceForm] = useState(false);
    const [activePlanningPreference, setActivePlanningPreference] = useState<UnavailableToWorkTimeSlot | null>(null);
    const [addShiftForUserData, setAddShiftForUserData] = useState<{ user: UserWithAvailability, date: Date } | null>(null);

    const startDate = getStartOfWorkDay(selectedDays[0]);
    const endDate = getEndOfWorkDay(selectedDays[selectedDays.length - 1]);

    const getUsersData = (): void => {
        dispatch(getUsersWithAvailability(canViewAbsences, canViewLeaveOfAbsences));
    };

    const handleSearchBarChange = (search: string): void => {
        dispatch(setUserSearch(search));
    };

    const handleCalendarTypeChange = (): void => {
        dispatch(switchMode());
    };

    const onSetFilterChange = (newFilter: AppFilter): void => {
        dispatch(setFilter(newFilter, getUsersData));
    };

    const dispatchEditLeaveOfAbsence = (
        editType: LeaveOfAbsenceEditType,
        data: LeaveOfAbsenceFormData,
        payrollPeriodsWithHours: PayrollPeriodWithHours[],
        weeksWithHours: WeekWithHours[],
        leaveType?: LeaveType | PzLeaveType | null,
    ): void => {
        setResolveLeaveOfAbsenceForm(false);
        setActiveLeaveOfAbsence(null);

        dispatch(editLeaveOfAbsence({
            editType,
            data,
            payrollPeriodsWithHours,
            weeksWithHours,
            leaveOfAbsence: activeLeaveOfAbsence as LeaveOfAbsence,
            leaveType,
            page: LIST_VIEW,
        }));
    };

    const dispatchDeclineLeaveOfAbsence = (declineComment: string): void => {
        setResolveLeaveOfAbsenceForm(false);
        setDeclineLeaveOfAbsenceForm(false);
        setActiveLeaveOfAbsence(null);
        setEditLOAData(null);
        setEditLOAPayrollPeriodHours([]);
        setEditLOAWeekdayHours([]);

        if (!editLOAData || !activeLeaveOfAbsence) {
            return;
        }

        dispatch(editLeaveOfAbsence({
            editType: LeaveOfAbsenceEditType.deny,
            data: editLOAData,
            payrollPeriodsWithHours: editLOAPayrollPeriodHours,
            weeksWithHours: editLOAWeekdayHours,
            leaveOfAbsence: activeLeaveOfAbsence,
            declineComment,
            page: LIST_VIEW,
        }));
    };

    const addDeclineLeaveOfAbsenceForm = (
        data: LeaveOfAbsenceFormData,
        payrollPeriodsWithHours: PayrollPeriodWithHours[],
        weeksWithHours: WeekWithHours[],
    ): void => {
        setResolveLeaveOfAbsenceForm(false);
        setDeclineLeaveOfAbsenceForm(true);
        setEditLOAPayrollPeriodHours(payrollPeriodsWithHours);
        setEditLOAWeekdayHours(weeksWithHours);
        setEditLOAData(data);
    };

    const dispatchEditAbsence = (
        absence: Absence,
        newStartDate: string,
        newEndDate: string | null,
        payrollPeriodsWithHours: PayrollPeriodWithHours[],
        weeksWithHours: WeekWithHours[],
        waitingDayHours: number,
    ): void => {
        dispatch(editAbsence(
            absence,
            newStartDate,
            newEndDate,
            payrollPeriodsWithHours,
            weeksWithHours,
            waitingDayHours,
            LIST_VIEW,
        ));
        setEditAbsenceForm(false);
    };

    const dispatchDeleteAbsence = (absenceId: string): void => {
        dispatch(deleteAbsence(absenceId, LIST_VIEW));
        setEditAbsenceForm(false);
    };

    const dispatchAvailabilityTime = (
        weekday: number,
        fromDate: string,
        toDate: string,
        comment: string,
    ): void => {
        const { user, id } = activePlanningPreference as UnavailableToWorkTimeSlot;
        setEditPlanningPreferenceForm(false);
        setActivePlanningPreference(null);
        dispatch(setUserPlanningPreference(
            weekday,
            fromDate,
            toDate,
            comment,
            user.id,
            id,
            LIST_VIEW,
        ));
    };

    const dispatchPlanUserToShift = (user: UserWithAvailability, shiftId: string): void => {
        setAddShiftForUserData(null);
        dispatch(planAvailabilityUserToShift(shiftId, user.id));
        getUsersData();
    };

    const dispatchGetLeaveOfAbsencesLogs = (id: string): void => {
        dispatch(getResourceLogs(id, 'leave-of-absences'));
    };

    const dispatchCloseLogsModal = (): void => {
        dispatch(clearResourceLogs());
    };

    const onSubmitLeaveOfAbsenceForm = (
        editType: LeaveOfAbsenceEditType,
        data: LeaveOfAbsenceFormData,
        payrollPeriodsWithHours: PayrollPeriodWithHours[],
        weeksWithHours: WeekWithHours[],
        leaveType?: LeaveType | PzLeaveType | null,
    ): void => {
        if (editType === 'deny') {
            addDeclineLeaveOfAbsenceForm(data, payrollPeriodsWithHours, weeksWithHours);
        } else {
            dispatchEditLeaveOfAbsence(
                editType,
                data,
                payrollPeriodsWithHours,
                weeksWithHours,
                leaveType,
            );
        }
    };

    const onCancelLeaveOfAbsenceForm = (): void => {
        setResolveLeaveOfAbsenceForm(false);
    };

    const onCancelDeclineLeaveOfAbsenceForm = (): void => {
        setDeclineLeaveOfAbsenceForm(false);
        setActiveLeaveOfAbsence(null);
        setEditLOAPayrollPeriodHours([]);
        setEditLOAData(null);
    };

    const onCancelEditAbsence = (): void => {
        setEditAbsenceForm(false);
    };

    const cancelPlanningPreferenceForm = (): void => {
        setEditPlanningPreferenceForm(false);
        setActivePlanningPreference(null);
    };

    const onCancelAddShiftForUserData = (): void => {
        setAddShiftForUserData(null);
    };

    const onResourceClick = (resource: AvailabilityResourceTypes): void => {
        if (resource.type === 'absences') {
            setEditAbsenceForm(true);
            setActiveAbsence(resource as Absence);
        }

        if (resource.type === 'leaveOfAbsences') {
            setResolveLeaveOfAbsenceForm(true);
            setActiveLeaveOfAbsence(resource as LeaveOfAbsence);
        }

        if (resource.type === 'shiftPlannings') {
            navigate(`${PrivateRouteGroupRoutes.shifts()}/${ScheduleRoutes.shift(resource.shift.id)}`);
        }

        if (resource.type === 'UnavailableToWorkTimeSlots') {
            setEditPlanningPreferenceForm(true);
            setActivePlanningPreference(resource as UnavailableToWorkTimeSlot);
        }
    };

    const onAddButtonClick = (date: Date, user: UserWithAvailability): void => {
        setAddShiftForUserData({ user, date });
    };

    useEffect((): void => {
        dispatch(fetchSpecialDays(addDays(startDate, -1), addSeconds(endDate, 1)));
    }, [selectedDays]);

    useEffect((): void => {
        if (timeMode !== TimeModeType.week && timeMode !== TimeModeType.custom) {
            dispatch(setTimeMode(TimeModeType.week));
        }

        if (!legacyPayrollPeriods.length) {
            dispatch(getPayrollPeriods());
        }

        if (!payrollPeriods.length) {
            dispatch(getPacoPayrollPeriods());
        }
    }, []);

    useEffect((): void => {
        dispatch(setPaginationNumber(1));
    }, [departmentOptions, userSearch]);

    useEffect((): void => {
        getUsersData();
    }, [selectedDays, departmentOptions, userSearch]);

    useEffect((): void => {
        if (isEditSuccessful) {
            getUsersData();
        }
    }, [isEditSuccessful]);

    return (
        <>
            <SidebarPage
                sidebarContent={(
                    <NewFilters
                        filter={filter}
                        filterBlocks={[
                            'availability',
                            'availability-sorting',
                            'main-department',
                            'employment-type-badge',
                        ]}
                        permissions={permissions}
                        onChange={onSetFilterChange}
                    />
                )}
            >
                <ListView
                    canViewShiftConcepts={canViewShiftConcepts}
                    firstRowSticky
                    firstColumnSticky
                    loading={loading}
                    days={selectedDays}
                    filter={filter}
                    users={users}
                    pagination={pagination}
                    canAddPlannings={canAddPlannings}
                    specialDays={specialDays}
                    startDate={startDate}
                    endDate={endDate}
                    onSearchBarChange={handleSearchBarChange}
                    getUsersData={getUsersData}
                    onResourceClick={onResourceClick}
                    onAddButtonClick={onAddButtonClick}
                    onCalendarTypeChange={handleCalendarTypeChange}
                />
            </SidebarPage>
            <Modal size="lg" isOpen={resolveLeaveOfAbsenceForm} className="form-resolve-leave-of-absence">
                <ResolveLeaveOfAbsenceForm
                    absence={activeLeaveOfAbsence}
                    permissions={permissions}
                    currentUserRole={currentUserRole}
                    pacoPayrollPeriods={payrollPeriods}
                    payrollPeriods={legacyPayrollPeriods}
                    onSubmit={onSubmitLeaveOfAbsenceForm}
                    onCancel={onCancelLeaveOfAbsenceForm}
                    onShowLogsClick={dispatchGetLeaveOfAbsencesLogs}
                />
            </Modal>
            <Modal size="lg" isOpen={declineLeaveOfAbsenceForm} className="form-decline-leave-of-absence">
                <DeclineLeaveOfAbsenceForm
                    onSubmit={dispatchDeclineLeaveOfAbsence}
                    onCancel={onCancelDeclineLeaveOfAbsenceForm}
                />
            </Modal>
            <Modal size="lg" isOpen={editAbsenceForm} className="form-resolve-absence">
                <EditAbsenceForm
                    data={activeAbsence as Absence}
                    permissions={permissions}
                    currentUserRole={currentUserRole}
                    pacoPayrollPeriods={payrollPeriods}
                    payrollPeriods={legacyPayrollPeriods}
                    onSubmit={dispatchEditAbsence}
                    onDelete={dispatchDeleteAbsence}
                    onCancel={onCancelEditAbsence}
                />
            </Modal>
            <Modal size="lg" isOpen={editPlanningPreferenceForm} className="form-add-time">
                <AddOrEditAvailabilityTime
                    data={activePlanningPreference}
                    permissions={permissions}
                    onCancel={cancelPlanningPreferenceForm}
                    onSubmit={dispatchAvailabilityTime}
                />
            </Modal>
            <Modal size="lg" isOpen={!!addShiftForUserData} className="form-assign-shift-to-user">
                <AssignShiftToUserForm
                    user={addShiftForUserData ? addShiftForUserData.user : null}
                    date={addShiftForUserData ? addShiftForUserData.date : null}
                    onSubmit={dispatchPlanUserToShift}
                    onCancel={onCancelAddShiftForUserData}
                />
            </Modal>
            <LogsModal
                loading={logsLoading}
                isOpen={logsLoading || !!logs}
                title={translate('logs.leaveOfAbsenceLog')}
                logs={logs}
                onClose={dispatchCloseLogsModal}
            />
        </>
    );
};

export default ConnectedListView;
