import { BaseScheduleShiftViewModel } from '../../entities/BaseScheduleShift/BaseScheduleShift';
import { ShiftConceptViewModelOld } from '../../entities/ShiftConcept/ShiftConcept';
import { areIntervalsOverlapping } from '../../helpers/date';
import { LeaveOfAbsenceViewModel, ShiftPlanningViewModel, ShiftViewModel } from '../../models';

const doesLeaveOfAbsenceOverlapWithShiftPlanning = (
    leaveOfAbsence: LeaveOfAbsenceViewModel,
    shiftPlanning: ShiftPlanningViewModel
    | ShiftViewModel
    | ShiftConceptViewModelOld
    | BaseScheduleShiftViewModel,
): boolean => {
    try {
        return areIntervalsOverlapping(
            {
                start: shiftPlanning.start,
                end: shiftPlanning.end.date,
            },
            {
                start: leaveOfAbsence.start,
                end: leaveOfAbsence.end.date,
            },
        );
    } catch (e) {
        console.error(
            '[doesLeaveOfAbsenceOverlapWithShiftPlanning] Invalid date range',
            { shiftPlanning, leaveOfAbsence },
            'Exception:',
            e,
        );
    }

    return false;
};

export default doesLeaveOfAbsenceOverlapWithShiftPlanning;
