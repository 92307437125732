import { ExchangeViewModel, ShiftExchangeViewModel } from '../../../../../models';
import { ShiftIndex } from '../../../../entities/Shift/Shift';
import { ShiftPlanning } from '../../../../entities/ShiftPlanning/ShiftPlanning';
import { isBefore } from '../../../../helpers/date';
import trans from '../../../../helpers/trans';
import { ShiftStatusType } from '../CalendarShift';

const getCalendarShiftStatus = (shift: ShiftIndex): ShiftStatusType => {
    if (isBefore(shift.period.start, new Date())) {
        return ShiftStatusType.past;
    }

    const plannedShiftPlannings = shift.shiftPlannings.filter(shiftPlanning => shiftPlanning.planned);

    if ((plannedShiftPlannings.length + shift.temporaryWorkers.length) >= shift.peopleLimit) {
        return ShiftStatusType.full;
    }

    return ShiftStatusType.available;
};

const getRegisteredShiftPlanningsText = (shiftPlannings: ShiftPlanning[]): string | undefined => {
    const registeredShiftPlannings = shiftPlannings.filter(shiftPlanning => !shiftPlanning.planned);

    if (registeredShiftPlannings.length === 1) {
        return trans('compositions.calendarShift.oneSignup');
    }

    if (registeredShiftPlannings.length > 1) {
        return trans('compositions.calendarShift.multipleSignups', { amount: registeredShiftPlannings.length.toString() });
    }

    return undefined;
};

const isUserInExchangeRequests = (exchangeRequests: ExchangeViewModel[], shiftPlanning: ShiftPlanning): boolean => {
    const userInExchangeRequest = (exchangeRequest: ExchangeViewModel): boolean => !!exchangeRequest.shift?.shiftPlannings?.some(shiftPlanningItem => {
        const shiftPlanningMatches = shiftPlanningItem.id === shiftPlanning.id;
        const userMatches = exchangeRequest.logs?.[0]?.actor?.uuid === shiftPlanning.user?.id;

        return shiftPlanningMatches && userMatches;
    });

    return exchangeRequests.some(userInExchangeRequest);
};

const isUserInShiftExchanges = (shiftExchanges: ShiftExchangeViewModel[], shiftPlanning: ShiftPlanning): boolean => {
    const userInShiftExchange = (shiftExchange: ShiftExchangeViewModel): boolean => {
        const shiftPlanningMatches = shiftExchange.shiftPlanning1.id === shiftPlanning.id;
        const userMatches = shiftExchange.shiftPlanning1.user?.id === shiftPlanning.user?.id;

        return shiftPlanningMatches && userMatches;
    };

    return shiftExchanges.some(userInShiftExchange);
};

export {
    getCalendarShiftStatus,
    getRegisteredShiftPlanningsText,
    isUserInExchangeRequests,
    isUserInShiftExchanges,
};
