import {
    ChangeEvent,
    FC,
    HTMLInputTypeAttribute,
    useMemo,
} from 'react';

import { format } from 'date-fns';

import { Icon, Input } from '../../../../components';
import { getStartOfWeekByYearAndWeekNumber } from '../../../../helpers/date';
import { TimeModeType } from '../../../../types';
import { DateFormat } from '../../../../types/dateFormatTypes';
import { getCalendarNavigationDateInputLabel, transformDatesToInputValue, transformStringToDate } from '../../helpers';

import './DateRangeInput.scss';

export type HTMLInputProps = JSX.IntrinsicElements['input'];

interface DateRangeInputProps extends HTMLInputProps {
    hasCaret?: boolean;
    dateFormat?: DateFormat;
    selectedDays: Date[];
    timeMode?: TimeModeType;
    type: HTMLInputTypeAttribute;
    onDateChange: (date: Date) => void;
    className?: string;
    labelClassName?: string;
}

const browserSupportsInputTypeWeek = (): boolean => {
    const input = document.createElement('input');
    input.setAttribute('type', 'week');

    return input.type === 'week';
};

const DateRangeInput: FC<DateRangeInputProps> = ({
    hasCaret,
    dateFormat,
    selectedDays,
    timeMode,
    max,
    min,
    type,
    onDateChange,
    className = '',
    labelClassName = '',
}) => {
    const label = useMemo(() => getCalendarNavigationDateInputLabel(selectedDays, timeMode, dateFormat), [selectedDays]);
    const inputValue = useMemo(() => transformDatesToInputValue(selectedDays, timeMode), [selectedDays, timeMode]);

    let targetType = type;
    let targetInputValue = inputValue;

    // Polyfill for input[type=week]
    if (targetType === 'week' && !browserSupportsInputTypeWeek()) {
        targetType = 'date';

        // Convert week notations like 2022-W48 to YYYY-MM-DD, where DD is set to the Monday for that week
        targetInputValue = inputValue?.includes('-W')
            ? format(
                getStartOfWeekByYearAndWeekNumber(inputValue),
                'yyyy-MM-dd',
            )
            : inputValue;
    }

    const handleChangeInput = (e: ChangeEvent<HTMLInputElement>): void => {
        const { value } = e.target;

        if (typeof value === 'object') {
            onDateChange(new Date(value));
        }

        return onDateChange(transformStringToDate(value, new Date()));
    };

    return (
        <div className={`date-range-input ${className}`}>
            <Input
                max={max}
                min={min}
                type={targetType}
                value={targetInputValue || ''}
                onChange={handleChangeInput}
                className="date-range-input__input"
            />
            <div className={`date-range-input__label ${labelClassName}`}>
                {label}
                {hasCaret && (
                    <Icon name="chevron-down" className="date-range-input__label-caret" />
                )}
            </div>
        </div>
    );
};

export default DateRangeInput;
