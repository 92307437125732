import { EditShiftPlanningTimeFormData } from '../../../models';
import { authorizedFetch } from '../../helpers/authorizedFetch';
import { formatDate, getEndOfWorkDay, getStartOfWorkDay } from '../../helpers/date';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import getIncluded from '../../japi/getIncluded';
import getMultipleIncluded from '../../japi/getMultipleIncluded';
import isResourceCollectionDocument from '../../japi/guards/isResourceCollectionDocument';
import isResourceDocument from '../../japi/guards/isResourceDocument';
import { JapiDocument } from '../../japi/types/Document';
import { FetchResourceError } from '../../japi/types/FetchResourceError';
import { FormOption } from '../../types';
import { DateFormat } from '../../types/dateFormatTypes';
import { DepartmentResource } from '../Department/Department';
import { transformToDepartment } from '../Department/DepartmentTransformers';
import { DepartmentGroupResource } from '../DepartmentGroup/DepartmentGroup';
import { FetchCollectionResult, FetchResult, FetchResultType } from '../FetchResult';
import { ShiftPlanning, ShiftPlanningResource } from '../ShiftPlanning/ShiftPlanning';
import { getShiftPlanningResourceIncludesAndTransformToShiftPlanning } from '../ShiftPlanning/ShiftPlanningService';
import { TemporaryWorkerResource } from '../TemporaryWorker/TemporaryWorker';
import { getTemporaryWorkerResourceIncludesAndTransformToTemporaryWorker } from '../TemporaryWorker/TemporaryWorkerHelpers';
import { UserResource } from '../User/User';
import { transformToUser } from '../User/UserTransformers';
import {
    AddShiftFormData,
    CopyShiftFormData,
    EditShiftFormData,
    ExportShiftFormData,
    Shift,
    ShiftIndex,
    ShiftResource,
} from './Shift';
import {
    transformAddShiftFormDataToAddApiParams,
    transformEditShiftTimeFormDataToEditShiftRequest,
    transformToShift,
    transformToShiftIndex,
} from './ShiftTransformers';

export const postShiftApiCall = async (shiftFormData: AddShiftFormData): Promise<FetchResult<ShiftIndex, string>> => {
    const apiData = transformAddShiftFormDataToAddApiParams(shiftFormData);

    try {
        const url = generateApiUrl({ endpoint: '/shifts' });

        const response = await authorizedFetch(url, {
            method: 'POST',
            body: JSON.stringify({ data: apiData }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<ShiftResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const departmentResource = getIncluded(doc, doc.data, 'department') as DepartmentResource;
        const departmentGroupResource = getIncluded(doc, departmentResource, 'group') as DepartmentGroupResource;
        const department = transformToDepartment(departmentResource, departmentGroupResource);

        const shift = transformToShiftIndex(doc.data, department, []);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: shift,
        };
    } catch (error) {
        console.error('[postShiftApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

let getShiftsIndexApiCallAbortController = new AbortController();

export const getShiftsIndexApiCall = async (
    startDate: Date,
    endDate: Date,
    departments: string[],
    userSearch = '',
): Promise<FetchCollectionResult<ShiftIndex[], string>> => {
    getShiftsIndexApiCallAbortController.abort();
    getShiftsIndexApiCallAbortController = new AbortController();

    try {
        const url = generateApiUrl({
            endpoint: '/shifts',
            queryParams: {
                search: userSearch,
                ...(departments.length) && { departments: JSON.stringify(departments) },
                startDate: formatDate(startDate, DateFormat.apiDateTime),
                endDate: formatDate(endDate, DateFormat.apiDateTime),
            },
        });

        const response = await authorizedFetch(url, { signal: getShiftsIndexApiCallAbortController.signal });

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<ShiftResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const shifts = doc.data.map(shiftResource => {
            const departmentResource = getIncluded(doc, shiftResource, 'department') as DepartmentResource;
            const departmentGroupResource = getIncluded(doc, departmentResource, 'group') as DepartmentGroupResource;
            const department = transformToDepartment(departmentResource, departmentGroupResource);

            const shiftPlanningsResource = getMultipleIncluded(doc, shiftResource, 'shiftPlannings') as ShiftPlanningResource[];
            const shiftPlannings = shiftPlanningsResource
                .map(shiftPlanningResource => getShiftPlanningResourceIncludesAndTransformToShiftPlanning(doc, shiftPlanningResource))
                .filter(shiftPlanning => !!shiftPlanning) as ShiftPlanning[];

            const temporaryWorkersResource = getMultipleIncluded(doc, shiftResource, 'temporaryWorkers') as TemporaryWorkerResource[];
            const temporaryWorkers = temporaryWorkersResource
                .map(temporaryWorkerResource => getTemporaryWorkerResourceIncludesAndTransformToTemporaryWorker(doc, temporaryWorkerResource, shiftResource));

            const previouslyPlannedUsersResource = getMultipleIncluded(doc, shiftResource, 'previouslyPlannedUsers') as UserResource[];
            const previouslyPlannedUsers = previouslyPlannedUsersResource.map(userResource => transformToUser(userResource));

            return transformToShiftIndex(
                shiftResource,
                department,
                shiftPlannings,
                temporaryWorkers,
                previouslyPlannedUsers,
            );
        });

        return {
            amountOfPages: 1,
            status: response.status,
            type: FetchResultType.Success,
            data: shifts,
        };
    } catch (error) {
        console.error('[getShiftsIndexCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getShiftApiCall = async (shiftId: string): Promise<FetchResult<ShiftIndex, string>> => {
    try {
        const includes = [
            'department',
            'department.group',
            'shiftPlannings',
            'shiftPlannings.user.person',
            'shiftPlannings.user.experience',
            'shiftPlannings.user.employmentType',
            'shiftPlannings.comments',
            'previouslyPlannedUsers',
            'previouslyPlannedUsers.employmentType',
            'shiftPlannings.comments.owner',
            'shiftPlannings.comments.owner.employmentType',
            'shiftPlannings.comments.owner.person',
            'shiftPlannings.comments.owner.experience',
            'temporaryWorkers',
            'temporaryWorkers.comments',
            'temporaryWorkers.comments.owner',
            'temporaryWorkers.comments.owner.person',
        ];

        const url = generateApiUrl({
            endpoint: `/shifts/${shiftId}`,
            queryParams: {
                include: includes.join(','),
            },
        });

        const response = await authorizedFetch(url);

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<ShiftResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const shiftResource = doc.data;
        const departmentResource = getIncluded(doc, shiftResource, 'department') as DepartmentResource;
        const departmentGroupResource = getIncluded(doc, departmentResource, 'group') as DepartmentGroupResource;
        const department = transformToDepartment(departmentResource, departmentGroupResource);

        const shiftPlanningsResource = getMultipleIncluded(doc, shiftResource, 'shiftPlannings') as ShiftPlanningResource[];
        const shiftPlannings = shiftPlanningsResource
            .map(shiftPlanningResource => getShiftPlanningResourceIncludesAndTransformToShiftPlanning(doc, shiftPlanningResource))
            .filter(shiftPlanning => !!shiftPlanning) as ShiftPlanning[];

        const temporaryWorkersResource = getMultipleIncluded(doc, shiftResource, 'temporaryWorkers') as TemporaryWorkerResource[];
        const temporaryWorkers = temporaryWorkersResource
            .map(temporaryWorkerResource => getTemporaryWorkerResourceIncludesAndTransformToTemporaryWorker(doc, temporaryWorkerResource, shiftResource));

        const previouslyPlannedUsersResource = getMultipleIncluded(doc, shiftResource, 'previouslyPlannedUsers') as UserResource[];
        const previouslyPlannedUsers = previouslyPlannedUsersResource.map(user => transformToUser(user));

        const shift = transformToShiftIndex(
            shiftResource,
            department,
            shiftPlannings,
            temporaryWorkers,
            previouslyPlannedUsers,
        );

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: shift,
        };
    } catch (error) {
        console.error('[getShiftApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const exportShiftsApiCall = async (shiftFormData: ExportShiftFormData, departments: FormOption[], userSearch: string): Promise<FetchResult<string, string>> => {
    try {
        const start = formatDate(getStartOfWorkDay(new Date(shiftFormData.startDate)), DateFormat.apiDateTime);
        const end = formatDate(getEndOfWorkDay(new Date(shiftFormData.endDate)), DateFormat.apiDateTime);

        const departmentUuids = departments
            .filter(department => department.isChecked)
            .map(filteredDepartment => filteredDepartment.value);

        const url = generateApiUrl({
            endpoint: '/shifts/csv',
            queryParams: {
                end,
                start,
                search: userSearch,
                ...(departmentUuids.length && { departments: JSON.stringify(departmentUuids) }),
            },
        });

        const response = await authorizedFetch(url);

        if (!response.ok) {
            const doc = await response.json();
            const errorResponse = doc as FetchResourceError;
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: errorResponse.errors[0].detail || trans('errors.unknownError'),
            };
        }

        return {
            data: await response.text(),
            status: response.status,
            type: FetchResultType.Success,
        };
    } catch (error) {
        console.error('[exportShiftsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const copyShiftsApiCall = async (
    shiftFormData: CopyShiftFormData,
    shiftIds: string[],
    dateOffset: number,
): Promise<FetchResult<string, string>> => {
    try {
        const url = generateApiUrl({
            endpoint: '/shifts/copy',
            queryParams: {
                dateOffset,
                'shiftUuids[]': shiftIds.join('shiftUuids[]='),
                withUsers: shiftFormData.copyEmployees ? 1 : 0,
            },
        });

        const response = await authorizedFetch(url, { method: 'POST' });

        if (!response.ok) {
            const doc = await response.json();
            const errorResponse = doc as FetchResourceError;
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: errorResponse.errors[0].detail || trans('errors.unknownError'),
            };
        }

        return {
            data: await response.text(),
            status: response.status,
            type: FetchResultType.Success,
        };
    } catch (error) {
        console.error('[copyShiftsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const editShiftApiCall = async (
    shiftFormData: EditShiftFormData | EditShiftPlanningTimeFormData,
    shiftId: string,
): Promise<FetchResult<ShiftIndex, string>> => {
    const apiData = transformEditShiftTimeFormDataToEditShiftRequest(shiftFormData, shiftId);
    const includes = [
        'department',
        'department.group',
        'shiftPlannings',
        'shiftPlannings.user.person',
        'shiftPlannings.user.experience',
        'shiftPlannings.user.employmentType',
        'shiftPlannings.comments',
        'previouslyPlannedUsers',
        'previouslyPlannedUsers.employmentType',
        'shiftPlannings.comments.owner',
        'shiftPlannings.comments.owner.employmentType',
        'shiftPlannings.comments.owner.person',
        'temporaryWorkers',
        'temporaryWorkers.comments',
        'temporaryWorkers.comments.owner',
        'temporaryWorkers.comments.owner.person',
    ];

    try {
        const url = generateApiUrl({
            endpoint: `/shifts/${shiftId}`,
            queryParams: {
                include: includes.join(','),
            },
        });

        const response = await authorizedFetch(url, {
            method: 'PATCH',
            body: JSON.stringify({ data: apiData }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<ShiftResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const shiftResource = doc.data;
        const departmentResource = getIncluded(doc, shiftResource, 'department') as DepartmentResource;
        const departmentGroupResource = getIncluded(doc, departmentResource, 'group') as DepartmentGroupResource;
        const department = transformToDepartment(departmentResource, departmentGroupResource);

        const shiftPlanningsResource = getMultipleIncluded(doc, shiftResource, 'shiftPlannings') as ShiftPlanningResource[];
        const shiftPlannings = shiftPlanningsResource
            .map(shiftPlanningResource => getShiftPlanningResourceIncludesAndTransformToShiftPlanning(doc, shiftPlanningResource))
            .filter(shiftPlanning => !!shiftPlanning) as ShiftPlanning[];

        const temporaryWorkersResource = getMultipleIncluded(doc, shiftResource, 'temporaryWorkers') as TemporaryWorkerResource[];
        const temporaryWorkers = temporaryWorkersResource
            .map(temporaryWorkerResource => getTemporaryWorkerResourceIncludesAndTransformToTemporaryWorker(doc, temporaryWorkerResource, shiftResource));

        const previouslyPlannedUsersResource = getMultipleIncluded(doc, shiftResource, 'previouslyPlannedUsers') as UserResource[];

        const previouslyPlannedUsers = previouslyPlannedUsersResource.map(user => transformToUser(user));

        const shift = transformToShiftIndex(
            shiftResource,
            department,
            shiftPlannings,
            temporaryWorkers,
            previouslyPlannedUsers,
        );

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: shift,
        };
    } catch (error) {
        console.error('[editShiftTimeApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const deleteShiftApiCall = async (shiftId: string): Promise<FetchResult<boolean, string>> => {
    try {
        const url = generateApiUrl({ endpoint: `/shifts/${shiftId}` });

        const response = await authorizedFetch(url, { method: 'DELETE' });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: true,
        };
    } catch (error) {
        console.error('[deleteShiftApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getSimilarShiftsApiCall = async (shiftId: string): Promise<FetchCollectionResult<Shift[], string>> => {
    try {
        const url = generateApiUrl({ endpoint: `/shifts/similar/${shiftId}` });

        const response = await authorizedFetch(url);

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<ShiftResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const shifts = doc.data.map(transformToShift);

        return {
            amountOfPages: 1,
            status: response.status,
            type: FetchResultType.Success,
            data: shifts,
        };
    } catch (error) {
        console.error('[getSimilarShiftsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
