import { endOfDay, startOfDay } from 'date-fns';

import { getShiftPlanningsWithinRange } from '../../../entities/ShiftPlanning/ShiftPlanningsService';
import { areIntervalsOverlapping } from '../../../helpers/date';

export const doesUserHaveOverlappingShiftPlannings = async (
    userId: string,
    startDate: Date,
    endDate: Date,
): Promise<boolean> => {
    const shiftPlannings = await getShiftPlanningsWithinRange({
        filter: {
            userIds: [userId],
        },
        startDate: startOfDay(startDate),
        endDate: endOfDay(endDate),
        planned: true,
    });

    return shiftPlannings.some((shiftPlanning) => areIntervalsOverlapping(
        { start: shiftPlanning.start, end: shiftPlanning.end.date },
        { start: startDate, end: endDate },
    ));
};
