import React, {
    FC,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { PreferToWorkTimeSlotBadge, UnavailableToWorkTimeSlotBadge } from '../../@paco/components';
import { transformToPeriod } from '../../@paco/entities/Period/PeriodTransformers';
import { Setting } from '../../@paco/entities/Setting/Setting';
import { doesUnavailableToWorkTimeSlotOverlapWithPeriod } from '../../@paco/entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlotHelpers';
import { BaseScheduleShiftViewModel } from '../../entities/BaseScheduleShift/BaseScheduleShift';
import { PreferToWorkTimeSlotViewModel } from '../../entities/PreferToWorkTimeSlot/PreferToWorkTimeSlot';
import { transformPreferToWorkTimeSlotViewModelToPreferToWorkTimeSlot } from '../../entities/PreferToWorkTimeSlot/PreferToWorkTimeSlotTransformers';
import { ShiftConceptViewModelOld } from '../../entities/ShiftConcept/ShiftConcept';
import { UnavailableToWorkTimeSlotViewModel } from '../../entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlot';
import { transformUnavailableToWorkTimeSlotViewModelToUnavailableToWorkTimeSlot } from '../../entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlotTransformer';
import {
    AbsenceViewModel,
    LeaveOfAbsenceStatus,
    LeaveOfAbsenceViewModel,
    ShiftPlanningType,
    ShiftPlanningViewModel,
    ShiftViewModel,
} from '../../models';
import { doesHaveShiftConceptPlanningsInLast11h } from '../../pages/Schedule/helpers/doesHaveShiftConceptPlanningsInLast11h';
import {
    doesUserHaveOverlappingShiftPlannings,
} from '../../pages/Schedule/helpers/doesUserHaveOverlappingShiftPlannings';
import { isUserAgeBelow16AndShiftIsAfter19h } from '../../pages/Schedule/helpers/isUserAgeBelow16AndShiftIsAfter19h';
import doesAbsenceOverlapWithShiftPlanning from '../../services/AbsenceService/doesAbsenceOverlapWithShiftPlanning';
import doesLeaveOfAbsenceOverlapWithShiftPlanning from '../../services/LeaveOfAbsenceService/doesLeaveOfAbsenceOverlapWithShiftPlanning';
import doesPreferToWorkTimeSlotOverlapWithShiftPlanning from '../../services/PreferToWorkTimeSlotService/doesPreferToWorkTimeSlotOverlapWithShiftPlanning';
import AbsenceBadge from '../UserBadge/AbsenceBadge';
import CaoBadge from '../UserBadge/CaoBadge/CaoBadge';

import './EmployeeAvailabilityLabels.scss';

interface EmployeeAvailabilityLabelsProps {
    userId: string;
    shift: ShiftPlanningViewModel
    | ShiftViewModel
    | ShiftConceptViewModelOld
    | BaseScheduleShiftViewModel;
    absences: AbsenceViewModel[];
    leaveOfAbsences: LeaveOfAbsenceViewModel[];
    unavailableToWorkTimeSlots: UnavailableToWorkTimeSlotViewModel[];
    preferToWorkTimeSlots: PreferToWorkTimeSlotViewModel[];
    settings?: Setting[];
}

const EmployeeAvailabilityLabels: FC<EmployeeAvailabilityLabelsProps> = ({
    userId,
    shift,
    absences,
    leaveOfAbsences,
    unavailableToWorkTimeSlots,
    preferToWorkTimeSlots,
    settings,
}) => {
    const [userHasShiftPlanningInLast11hActive, setUserHasShiftPlanningInLast11hActive] = useState<boolean>(false);
    const [userHasOverlappingShiftPlanningActive, setUserHasOverlappingShiftPlanningActive] = useState<boolean>(false);

    const activeAbsence = useMemo(
        () => absences.find(absence => (
            userId === absence.user?.id
        && doesAbsenceOverlapWithShiftPlanning(absence, shift, new Date()))),
        [absences, shift],
    );

    const approvedLeaveOfAbsence = useMemo(
        () => leaveOfAbsences.find(leaveOfAbsence => (
            userId === leaveOfAbsence.user?.id
        && leaveOfAbsence.status === LeaveOfAbsenceStatus.approved
        && doesLeaveOfAbsenceOverlapWithShiftPlanning(leaveOfAbsence, shift))),
        [leaveOfAbsences, shift],
    );

    const openLeaveOfAbsence = useMemo(
        () => leaveOfAbsences.find(leaveOfAbsence => (
            userId === leaveOfAbsence.user?.id
        && leaveOfAbsence.status === LeaveOfAbsenceStatus.open
        && doesLeaveOfAbsenceOverlapWithShiftPlanning(leaveOfAbsence, shift))),
        [leaveOfAbsences, shift],
    );

    const unavailableToWorkTimeSlot = useMemo(
        () => unavailableToWorkTimeSlots
            .find((slot) => userId === slot.user?.id
                && doesUnavailableToWorkTimeSlotOverlapWithPeriod(
                    transformUnavailableToWorkTimeSlotViewModelToUnavailableToWorkTimeSlot(slot),
                    transformToPeriod(shift.start, shift.end.date),
                )),
        [unavailableToWorkTimeSlots, shift],
    );

    const preferToWorkTimeSlot = useMemo(
        () => preferToWorkTimeSlots
            .find((slot) => userId === slot.user?.id
                && doesPreferToWorkTimeSlotOverlapWithShiftPlanning(slot, shift)),
        [preferToWorkTimeSlots, shift],
    );

    useEffect((): void => {
        const userHasShiftPlanningInLast11h = async () => settings && doesHaveShiftConceptPlanningsInLast11h(settings, userId, shift.start);
        const userHasOverlappingShiftPlanning = async () => settings && doesUserHaveOverlappingShiftPlannings(userId, shift.start, shift.end.date);

        userHasShiftPlanningInLast11h().then(isSettingEnabled => setUserHasShiftPlanningInLast11hActive(!!isSettingEnabled));
        userHasOverlappingShiftPlanning().then(isSettingEnabled => setUserHasOverlappingShiftPlanningActive(!!isSettingEnabled));
    }, [settings]);

    const userAgeIsBelow16AndShiftIsAfter19h = settings && shift.type === ShiftPlanningType.shift && shift.user
        && isUserAgeBelow16AndShiftIsAfter19h(settings, shift.user.birthday, shift.end, new Date());

    return (
        <div className="employee-availability-labels">
            {userHasShiftPlanningInLast11hActive && <CaoBadge userHasShiftPlanningInLast11h />}
            {userHasOverlappingShiftPlanningActive && <CaoBadge userHasOverlappingShiftPlanning />}
            {userAgeIsBelow16AndShiftIsAfter19h && <CaoBadge userAgeIsBelow16AndShiftIsAfter19h />}
            {(activeAbsence || approvedLeaveOfAbsence || openLeaveOfAbsence) && (
                <AbsenceBadge
                    className="employee-option-label__badge"
                    absence={activeAbsence}
                    leaveOfAbsence={approvedLeaveOfAbsence}
                    leaveOfAbsenceRequest={openLeaveOfAbsence}
                    modifier="bg-white"
                />
            )}
            {unavailableToWorkTimeSlot && (
                <UnavailableToWorkTimeSlotBadge
                    unavailableToWorkTimeSlot={transformUnavailableToWorkTimeSlotViewModelToUnavailableToWorkTimeSlot(unavailableToWorkTimeSlot)}
                    className="employee-option-label__badge"
                />
            )}
            {preferToWorkTimeSlot && (
                <PreferToWorkTimeSlotBadge
                    preferToWorkTimeSlot={transformPreferToWorkTimeSlotViewModelToPreferToWorkTimeSlot(preferToWorkTimeSlot)}
                    className="employee-option-label__badge"
                />
            )}
        </div>
    );
};

export default EmployeeAvailabilityLabels;
