import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
    RefAttributes,
} from 'react';

import './Button.scss';

type HTMLButtonProps = JSX.IntrinsicElements['button'];

export interface ButtonProps extends HTMLButtonProps {
    text: string;
    hideLabel?: boolean;
}

export type ButtonWithRefProps = ButtonProps & RefAttributes<HTMLButtonElement>;

const Button: ForwardRefExoticComponent<ButtonWithRefProps> = forwardRef(({
    type = 'button',
    text,
    hideLabel,
    className = '',
    children,
    ...buttonProps
}, ref: Ref<HTMLButtonElement>): ReactElement => (
    <button
        {...buttonProps}
        ref={ref}
        type={type} // eslint-disable-line react/button-has-type
        title={hideLabel || children ? text : undefined}
        className={`button ${className}`}
    >
        {!hideLabel && (children || text) as any}
    </button>
));

export default Button;
